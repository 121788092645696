var VirtualMarketAccelerator = VirtualMarketAccelerator || {};

VirtualMarketAccelerator.App = function () {
    "use strict";
    $('.alert-box.success').delay(3000).fadeOut();
    smoothScroll.init();
    new VirtualMarketAccelerator.Notifications();
};

$(function() {
    "use strict";
    new VirtualMarketAccelerator.App();
    $.ajaxSetup({
        headers: { 'X-CSRF-Token' : $('meta[name=_token]').attr('content') }
    });

    $('.toggle-overpane').click(function(e) {
        e.preventDefault();
        $('.overpane').toggleClass('show')
    });
});