var vma = {
    log: function() {
        if (window['console'] !== undefined){
            console.log(arguments[0]);
        }
    },
    clickIfNotDisabled: function(me, callback) {
        if (me.hasClass('disabled')) {
            return false;
        }
        callback();
    },
    clearForm: function(selector) {
        $(selector).find('input').each(function(){
            $(this).val('');
        });
        $(selector).find('select').each(function(){
            $(this).val('');
        });
        $(selector).find('select.select2').each(function(){
            $(this).select2("val",'');
        });
    }
};

var smoothScroll = {
    selector: '.smoothScroll',
    init: function() {
        $(this.selector).click(function(e) {
            e.preventDefault();
            var target = $(this).data('target');
            //console.log(target);
            var $target = $(target);
            $("html,body").animate({scrollTop: $target.offset().top}, "500", function() {
                $target.parent().addClass('target',500).removeClass('target',500);
            });
            return false;
        });
    }
}

var cookies = {
    set: function (cname, cvalue, exdays) {
        if (typeof(exdays) == 'undefined') {
            var expires = "";
        }
        else {
            var d = new Date();
            d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
        }
        document.cookie = cname + "=" + cvalue + "; path=/;" + expires;
    },

    get: function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    },

}
