var userSearch = {
    init: function() {
        vma.log('Init userSearch');
        $('.js-filter.filters .js-apply').click(function(e) {
            e.preventDefault();
            userSearch.collectAndPostData();
        });
        $('.js-filter.filters .js-clear').click(function(e) {
            e.preventDefault();
            $('select.select2').select2('val', '');
        });

        $('.js-filter.orders .js-filter .js-ascdesc').click(function(e) {
            e.preventDefault();
            if ($(this).parent('.js-filter').hasClass('disabled')) {
                $('.js-filter.orders .js-filter.active').addClass('disabled').removeClass('active');
                $(this).parent('.js-filter').removeClass('disabled').addClass('active');
                $(this).children('.fa').addClass('fa-sort-asc').removeClass('fa-sort-desc');
            }
            else {
                if($(this).children('.fa').hasClass('fa-sort-asc')) {
                    $(this).children('.fa').addClass('fa-sort-desc').removeClass('fa-sort-asc');
                }
                else {
                    $(this).children('.fa').addClass('fa-sort-asc').removeClass('fa-sort-desc');
                }
            }
            userSearch.collectAndPostData();
        });
        $('.js-filter.orders .js-filter .js-remove').click(function(e) {
            e.preventDefault();
            if ($(this).parent('.js-filter').hasClass('active')) {
                $(this).parent('.js-filter').addClass('disabled').removeClass('active');
            }
            userSearch.collectAndPostData();
        });
    },
    collectAndPostData: function() {
        var filters = {};
        var sortby = [];
        filters.skills = $('select[name="skills[]"]').select2('val');
        filters.provinces = $('select[name="provinces[]"]').select2('val');

        $('.js-filter.orders .js-filter.order.active').each(function() {
            var field = $(this).data('name');
            vma.log(field);
            vma.log($(this).find('.fa').first().attr('class'));
            if ($(this).find('.fa').first().hasClass('fa-sort-asc')) {
                vma.log(field + " ASC");
                sortby.push([field, 'ASC']);
            }
            else {
                vma.log(field + " DESC");
                sortby.push([field, 'DESC']);
            }
        });
        filters.sortby = sortby;

        vma.log(filters);
        $('input[name=filters]').val(JSON.stringify(filters));
        $('form').submit();
    }
}



$(function(){
    userSearch.init();
});