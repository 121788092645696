var VirtualMarketAccelerator = VirtualMarketAccelerator || {};

VirtualMarketAccelerator.Notifications = function () {
    "use strict";
    this.url = '';
    this.$notifications = $('#notifications-topbar');
    this.pollingServer = this.$notifications.data('pollingserver');
    this.markRead = this.$notifications.data('markread');
    this.interval = 30000;

    if(this.$notifications.length) {
        this.startPolling();
        var that = this;
        this.$notifications.mouseenter(function() {
            $.ajax({
                url:that.markRead,
                method: 'POST',
                dataType: 'html',
                success: function(data) {
                    that.$notifications.find('li.not-read-notification').removeClass('not-read-notification').addClass('read-notification');
                }
            });
        });
    }
};

VirtualMarketAccelerator.Notifications.prototype.startPolling = function () {
    "use strict";
    var that = this;
    setTimeout(function () {
        $.ajax({ url: that.pollingServer, success: function (data) {
            that.updateData(data);
            that.startPolling();
        }, dataType: "html"});
    }, that.interval);
};

VirtualMarketAccelerator.Notifications.prototype.updateData = function (value) {
    "use strict";
    this.$notifications.find('.dropdown').prepend(value);
    this.updateCount(this.$notifications.find('li.not-read-notification').length);
};

VirtualMarketAccelerator.Notifications.prototype.updateCount = function (value) {
    "use strict";
    vma.log("notifications loaded: " + value);
    this.$notifications.find('.notifications-count').text(value);
    if (value>0) {
        this.$notifications.find('.notifications-count').show();
    }
    else {
        this.$notifications.find('.notifications-count').hide();
    }
};