var forms = {
    dataTable: {
        selector: 'table.dataTable',
        editableSelector: 'editable',
        picktableSelector: 'table'+'.picktable',
        checkBoxesSelector: 'input[type=checkbox]',
        options: {
            "language": {
                "emptyTable": "No data."
            }
        },
        optionsCheckbox: {
            "language": {
                "emptyTable": "No data."
            },
            "columnDefs": [
                { "orderable": false, "targets": 0 }
            ],
            "order": [[1, "asc"]]
        },
        init: function() {
            // JQuery DataTable
            $(this.selector).each(function() {
                vma.log('Init dataTable on ');
                vma.log($(this));
                var that = forms.dataTable;
                options = jQuery.extend({}, forms.dataTable.options);
                vma.log(options);
                if($(this).find(that.checkBoxesSelector).length > 0){
                    options = that.optionsCheckbox;
                }

                if ($(this).hasClass(that.editableSelector)) {
                    $(this).find('tr td').click(function() {
                        if (!$(this).hasClass('selector') &&
                            !$(this).hasClass('dataTables_empty') &&
                            !$(this).parent('tr').hasClass('disabled')) {
                            window.location = $(this).parent('tr').find('td a').attr('href');
                        }
                    });
                    $(this).find('tr td a').click(function(e) {
                        if ($(this).parents('tr').hasClass('disabled')) {
                            e.preventDefault();
                            return false;
                        }
                    });
                }

                var orderCol = $(this).find('tr th.ordered').first();
                if (orderCol.length > 0) {
                    vma.log('ordered column found');
                    var order = orderCol.data('order');
                    var colIndex = $(this).find('tr th').index(orderCol);
                    options.order = [[colIndex, order]];
                }

                if ($(this).hasClass('onlyTable')) {
                    options.paging = false;
                    options.searching = false;
                    options.lengthChange = false;
                    options.info = false;
                }
                vma.log(options);
                $(this).DataTable(options);

            });

            // picktable azione sul click, prende l'href dal primo anchor che trova nella riga
            vma.log('Init dataTable Picktable on '+ this.picktableSelector);

            $(this.picktableSelector+' tr td').click(function() {
                if (!$(this).hasClass('selector') &&
                    !$(this).hasClass('dataTables_empty') &&
                    !$(this).parent('tr').hasClass('disabled')) {
                    vma.clearForm('.beforePicker');
                    $('.beforePicker').hide();
                    $(this).parent('tr').find('td').each(function() {
                        if ($(this).data('field') != 'undefined') {
                            if ($('input[data-field='+$(this).data('field')+']').length) {
                                vma.log($(this).data('field') + "=" +$(this).data('value'));
                                $('input[data-field=' + $(this).data('field') + ']').val($(this).data('value'));
                            }
                            if ($('select.select2[data-field='+$(this).data('field')+']').length) {
                                vma.log($(this).data('field') + "=" +$(this).data('value'));
                                $('select.select2[data-field='+$(this).data('field')+']').select2("val",$(this).data('value'));
                            }
                            if ($('p[data-field='+$(this).data('field')+']').length) {
                                $('p[data-field='+$(this).data('field')+']').text($(this).data('value'));
                            }
                        }
                    });
                    if ($(this).parent('tr').data('id') != 'undefined') {
                        vma.log("id=" +$(this).parent('tr').data('id'));
                        $('[data-field="id"]').val($(this).parent('tr').data('id'));
                    }
                    $('.afterPicker').show();
                    $('.overpane').toggleClass('show');
                }
            });
            $(this.picktableSelector+' tr td a').click(function(e) {
                e.preventDefault();
                $(this).parent('td').click();
                /*if ($(this).parents('tr').hasClass('disabled')) {
                    e.preventDefault();
                    return false;
                }
                vma.clearForm('.beforePicker');
                $('.beforePicker').hide();
                $(this).parent('tr').find('td').each(function() {
                    if ($(this).data('field') != 'undefined') {
                        if ($('input[data-field='+$(this).data('field')+']').length) {
                            vma.log($(this).data('field') + "=" +$(this).data('value'));
                            $('input[data-field=' + $(this).data('field') + ']').val($(this).data('value'));
                        }
                        if ($('select.select2[data-field='+$(this).data('field')+']').length) {
                            vma.log($(this).data('field') + "=" +$(this).data('value'));
                            $('select.select2[data-field='+$(this).data('field')+']').select2("val",$(this).data('value'));
                        }
                        if ($('p[data-field='+$(this).data('field')+']').length) {
                            $('p[data-field='+$(this).data('field')+']').text($(this).data('value'));
                        }
                    }
                });
                if ($(this).parent('tr').data('id') != 'undefined') {
                    vma.log("id=" +$(this).parent('tr').data('id'));
                    $('[data-field="id"]').val($(this).parent('tr').data('id'));
                }
                $('.afterPicker').show();
                $('.overpane').toggleClass('show');*/
            });
            $('.clear-picker').click(function(e) {
                e.preventDefault();
                $('.beforePicker').show();
                vma.clearForm('.afterPicker');
                $('.afterPicker').hide();
            });


            // vedo se inizializzare le bulkActions
            if ($(this.checkBoxesSelector).length > 0) {
                vma.log('Init bulkActions on '+ this.checkBoxesSelector);
                forms.dataTable.bulkActions.init();
                $(this.checkBoxesSelector).click(function () {
                    var collect = forms.dataTable.collectAllCheckbox(forms.dataTable.checkBoxesSelector);
                    if (collect.length > 0) {
                        forms.dataTable.bulkActions.deleteButton.enable();
                    }
                    else {
                        forms.dataTable.bulkActions.deleteButton.disable();
                    }
                });
            }
        },
        collectAllCheckbox: function(selector) {
            var ret = [];
            $(selector).each(function() {
                if ($(this).is(':checked')) {
                    ret.push($(this).val());
                }
            });
            return ret;
        },

        bulkActions: {
            init: function() {
                vma.log('Init bulkActions');
                this.deleteButton.disable();
                $(this.deleteButton.selector).click(this.deleteButton.onClick);
                $(this.selectAllButton.selector).click(this.selectAllButton.onClick);
            },
            deleteButton: {
                selector: '#side-actions .delete',
                disable: function() {
                    $(this.selector).addClass('disabled');
                },
                enable: function() {
                    $(this.selector).removeClass('disabled');
                },
                onClick: function(e) {
                    e.preventDefault();
                    if ($(this).hasClass('disabled')) {
                        return false;
                    }
                    var collect = forms.dataTable.collectAllCheckbox(forms.dataTable.checkBoxesSelector);
                    $(this).parent('form').children('input[name=ids]').val(collect.toString());
                    $(this).parent('form').submit();
                }
            },
            selectAllButton: {
                selector: '#side-actions .select-all',
                onClick: function () {
                    if ($(this).hasClass('disabled')) {
                        return false;
                    }
                    $(forms.dataTable.checkBoxesSelector).each(function () {
                        $(this).prop('checked', true);
                    });
                    forms.dataTable.bulkActions.deleteButton.enable();
                }
            }
        }
    },
    itemActions: {
        init: function() {
            vma.log('Init itemActions');
            $(this.deleteButton.selector).click(this.deleteButton.onClick);
            $(this.actionButton.selector).click(this.actionButton.onClick);
        },
        deleteButton: {
            selector: 'a.delete',
            onClick: function(e) {
                e.preventDefault();
                if ($(this).hasClass('disabled')) {
                    return false;
                }
                var formselector = $(this).data('deleteform');
                vma.log('Submitting form#'+formselector);
                $('#'+formselector).submit();
            }
        },
        actionButton: {
            selector: 'a.action',
            onClick: function(e) {
                e.preventDefault();
                if ($(this).hasClass('disabled')) {
                    return false;
                }
                var formselector = $(this).data('form');
                vma.log('Submitting form#'+formselector);
                $('#'+formselector).submit();
            }
        }
    },
    datePicker: {
        selector: 'input.datePicker',
        init: function() {
            vma.log('Init datePicker');
            $(this.selector).fdatepicker({
                language: app.locale,
                format: app.dateFormat,
            });
        }
    },
    selectTwo: {
        selector: 'select.select2',
        options : {
            placeholder: "Please select...",
            //allowClear: true
        },
        init: function () {
            vma.log('Init selectTwo');

            $(this.selector).each(function() {
                vma.log($(this));
                var options = jQuery.extend({},forms.selectTwo.options);
                if ($(this).hasClass('skills')) {
                    options.templateResult = forms.selectTwo.formatSkill;
                    options.templateSelection = forms.selectTwo.formatSelectedSkill;
                }
                vma.log(options);
                $(this).select2(options);
                if ($(this).attr('multiple')!='undefined') {
                    var values = [];
                    $(this).find('option[selected]').each(function () {
                        values.push($(this).val());
                    });
                    vma.log(values);
                    $(this).val(values);
                }
            });
        },
        formatSkill: function (skill) {
            var el = $(skill.element);
            if (!skill.id) {
                var txt = skill.text;
                for (var i = 0; i < (parseInt(el.data('level'))); i++) {
                    txt = '-- ' + txt;
                }
                return txt;
            }
            var pad = "";
            for(var i = 0; i<parseInt(el.data('level')); i++) {
                pad = pad + '<img class="select2 icon" src="/img/blank.png">';
            }
            var $skill = $(
                '<span>'+pad+'<img class="select2 icon" src="' + el.data('image') + '"> ' + skill.text + '</span>'
            );
            return $skill;
        },
        formatSelectedSkill: function (skill) {
            if (!skill.id) {
                return skill.text;
            }
            var pieces = skill.text.split('->');
            if (pieces.length > 1) {
                return pieces[1];
            }
            return skill.text;
        },
    },
    tinyMCE: {
        selector: 'textarea.tiny',
        init: function() {
            vma.log('Init tinymce');
            tinyMCE.baseURL = "/js";
            tinymce.init({
                selector: this.selector,
                menubar: false,
                content_css : "/css/app.css",
                theme_url: '/js/themes/modern/theme.min.js'
            })
        }
    },
    rating: {
        selector: '.feedback-stars.input',
        initialvalue: 0,
        init: function() {
            vma.log('Init rating');
            var current = parseInt($(this.selector).data('rating'));
            if (current != NaN) {
                this.initialvalue = current;
            }
            forms.rating.enableUntil(this.initialvalue);

            $(this.selector).find('i').each(function () {
                vma.log($(this));
                $(this).hover(function() {
                    var current = $(this).data('value');
                    forms.rating.enableUntil(current);
                }, function() {
                    forms.rating.enableUntil(forms.rating.initialvalue);
                }).click(function(e){
                    vma.log($(this).data('value'));
                    forms.rating.initialvalue = $(this).data('value');
                    $('.container').find('input[name=stars]').val($(this).data('value'));
                });
            });
        },
        enableUntil: function(value) {
            $(this.selector+' i').addClass('disabled');
            $(this.selector).find('i').each(function () {

                if ($(this).data('value') <= value) {
                    $(this).removeClass('disabled');
                }
            });
        }
    },
    answerTemplates: {
        selector: '.js-answer__template',
        init: function() {
            vma.log('Init answerTemplates');
            var url = $(this.selector).attr('data-template') + '/';
            var urlNoTemplate = $(this.selector).attr('data-no-template');
            $(this.selector).change(function () {
                "use strict";
                var val = $(this).val();
                if(val !== '0')
                {
                    window.location.href = url + val;
                } else {
                    window.location.href = urlNoTemplate;
                }
            });
        }
    }
    //itemPicker: {
    //    selector: 'a.itempickertarget',
    //    cookieName: 'itempickerdata',
    //    init: function() {
    //        vma.log('Init itempicker');
    //        $(this.selector).click(function(e) {
    //            e.preventDefault();
    //            var s_data = JSON.stringify({
    //                'form':$(this).parents('form').serialize(),
    //                'url':window.location.href
    //            });
    //            vma.log(s_data);
    //            if (window.sessionStorage) {
    //                sessionStorage.setItem(forms.itemPicker.cookieName, s_data)
    //            }
    //            window.location.href = $(this).attr('href');
    //        });
    //        if (window.sessionStorage) {
    //            var s_data = sessionStorage.getItem(forms.itemPicker.cookieName);
    //            if (s_data != null) {
    //                var data = JSON.parse(s_data);
    //                var url = data.url;
    //                var form = data.form;
    //                if (url == window.location.href) {
    //                    $.each(form.split('&'), function (index, elem) {
    //                        var vals = elem.split('=');
    //                        $("[name='" + vals[0] + "']").val(decodeURIComponent(vals[1].replace(/\+/g, ' ')));
    //                    });
    //                    sessionStorage.removeItem(forms.itemPicker.cookieName);
    //                }
    //            }
    //        }
    //    }
    //}
}


$(function() {
    forms.dataTable.init();
    forms.itemActions.init();
    forms.datePicker.init();
    forms.selectTwo.init();
    forms.tinyMCE.init();
    forms.rating.init();
    forms.answerTemplates.init();
    //forms.fileInput.init();
    //forms.itemPicker.init();
});